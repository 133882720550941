import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import TableFeatures from 'components/Indoor_Cameras/IN-8003_HD/Product_Features/TableFeatures';
import NavButtons from 'components/Indoor_Cameras/IN-8003_HD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-8003 Full HD Manual Product Features",
  "path": "/Indoor_Cameras/IN-8003_HD/Product_Features/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "The IN-8003 HD is the first INSTAR camera with the new 1080p chipset.",
  "image": "./P_SearchThumb_IN-8003HD_Features.png",
  "social": "/images/Search/P_SearchThumb_IN-8003HD_Features.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-8003HD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='IN-8003 Full HD Manual Product Features' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-8003 HD is the first INSTAR camera with the new 1080p chipset.' image='/images/Search/P_SearchThumb_IN-8003HD_Features.png' twitter='/images/Search/P_SearchThumb_IN-8003HD_Features.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-8003_HD/Produkt_Features/' locationFR='/fr/Indoor_Cameras/IN-8003_HD/Product_Features/' crumbLabel="Features" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-8003-full-hd-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-8003-full-hd-manual",
        "aria-label": "in 8003 full hd manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-8003 Full HD Manual`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/397f0e4e9ef088b36cce11707a5ec797/573d3/IN-8003_Camera_Features.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAzklEQVQoz5WRiQ7EIAhE/f9/1R5WxV6zGTaYtuluWhOCBzwccACwbRvWdYWIYJ5nLMuift93Pjf/ZDkDEjJNE0opCiOcRa5A7o+FrqZAwgiKMSKlpDDe/Uv8ZQ1YRRTmvUfJuUl+I/ckmcZk66HJHccROSU9D8PQ+kxFvGMez/wMzZkke6S3oXDVWjWZoL7vEUL4eh/QdR1yzhpDGOPaD3nJRynSBnIn1wpb8WtbnAURKKVo/7g/DuXJOg2FQOud9e/4w9dTNqD17w74dMofg8wWJ6sN40EAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/397f0e4e9ef088b36cce11707a5ec797/e4706/IN-8003_Camera_Features.avif 230w", "/en/static/397f0e4e9ef088b36cce11707a5ec797/d1af7/IN-8003_Camera_Features.avif 460w", "/en/static/397f0e4e9ef088b36cce11707a5ec797/7f308/IN-8003_Camera_Features.avif 920w", "/en/static/397f0e4e9ef088b36cce11707a5ec797/e1c99/IN-8003_Camera_Features.avif 1380w", "/en/static/397f0e4e9ef088b36cce11707a5ec797/e02f7/IN-8003_Camera_Features.avif 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/397f0e4e9ef088b36cce11707a5ec797/a0b58/IN-8003_Camera_Features.webp 230w", "/en/static/397f0e4e9ef088b36cce11707a5ec797/bc10c/IN-8003_Camera_Features.webp 460w", "/en/static/397f0e4e9ef088b36cce11707a5ec797/966d8/IN-8003_Camera_Features.webp 920w", "/en/static/397f0e4e9ef088b36cce11707a5ec797/445df/IN-8003_Camera_Features.webp 1380w", "/en/static/397f0e4e9ef088b36cce11707a5ec797/44758/IN-8003_Camera_Features.webp 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/397f0e4e9ef088b36cce11707a5ec797/81c8e/IN-8003_Camera_Features.png 230w", "/en/static/397f0e4e9ef088b36cce11707a5ec797/08a84/IN-8003_Camera_Features.png 460w", "/en/static/397f0e4e9ef088b36cce11707a5ec797/c0255/IN-8003_Camera_Features.png 920w", "/en/static/397f0e4e9ef088b36cce11707a5ec797/b1001/IN-8003_Camera_Features.png 1380w", "/en/static/397f0e4e9ef088b36cce11707a5ec797/573d3/IN-8003_Camera_Features.png 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/397f0e4e9ef088b36cce11707a5ec797/c0255/IN-8003_Camera_Features.png",
              "alt": "IN-8003 Full HD Produkt Features",
              "title": "IN-8003 Full HD Produkt Features",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TableFeatures mdxType="TableFeatures" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-8003 is the newest Full HD PIR Indoor and Outdoor IP Camera from INSTAR. With a built-in passive infrared (PIR) motion sensor and four separately configurable alarm areas that can be linked together, the camera prevents false alarms and notifies you only when a real alarm has been triggered.`}</p>
    <p>{`Equipped with one of the latest Panasonic Full HD CMOS image sensor, which features low noise, high speed and new Wide Dynamic Range (WDR) functionality, the IN-8003 is able to achieve the best image quality in daylight as well as clear image in night vision mode with the help of its high-power LEDs. Hence, the IN-8003 is able to deliver accurate motion detection and alarm notification.`}</p>
    <p>{`The design of this camera was completely new developed in order to fullfill the wishes of our customers. If you need to pull the cable through the wall, a small hole in the size of the cable diameter would be sufficient, as you can simply take off the cable from inside the casing, pull it through the wall and connect the cable back to the board inside.`}</p>
    <p>{`This camera is running an embedded Linux operating system and a Webserver which supports TLS/SSL encryption for security and privacy. It provides a built-in, modern HTML5 based Web User Interface (WebUI) with focus on usability and simplicity, supporting all recent devices (desktop, tablet, smartphone) and operating systems (Windows, MacOS, Linux,…). Thanks to the built-in Camera Setup Wizard and the (built-in) help function, INSTAR users can enjoy a very easy initial set-up of the IP camera.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      